/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import InnerTitle from "../components/InnerTitle";
import AdvocatesList from "../components/AdvocatesListNew";
import SponsorSlider from "../components/SponsorSlider";

const Page = ({ data }) => {
  const { sponsors } = data.wp.acfOptionsSponsors.sponsors;
  const { title, content, seo } = data.wpPage;
  const { sidebarMenus } = data.wpPage.sidebarMenuSelect;

  const chunkedSponsors = sponsors.reduce((acc, sponsor) => {
    const level =
      sponsor.level?.[1] == "Attorney"
        ? sponsor.level[0] + " Advocate " + sponsor.level[1]
        : sponsor.level[0];
    if (!acc[level]) acc[level] = [];
    acc[level].push(sponsor);
    return acc;
  }, {});

  const sponsorKeys = Object.keys(chunkedSponsors);

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle>{title}</InnerTitle>
          <PageContent content={content} />
          {sponsorKeys.map((key, index) => (
            <AdvocatesList
              key={index}
              title={key}
              sponsors={chunkedSponsors[key]}
            />
          ))}
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs menus={sidebarMenus} />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
    }
    wp {
      acfOptionsSponsors {
        sponsors {
          sponsors {
            name
            isDraft
            categories
            isDraft
            contact {
              email
              name
              phone
            }
            content
            level
            logo {
              ...GatsbyImage_Blur
            }
          }
        }
      }
    }
  }
`;
