import React from "react";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link as Glink } from "gatsby";

const Section = tw.section`flex flex-col w-full relative mt-8 mb-12`;
const Title = tw.h3`flex text-xl sm:text-2xl text-offblack flex-row items-center w-full whitespace-nowrap font-din mb-4 font-normal after:(content[''] bg-offblack bg-opacity-50 ml-8 w-full h-0.5 flex)`;
const Grid = tw.div`2xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid grid-cols-1 gap-8`;
const GridItem = styled.div(({ single, double }) => [
  tw`flex flex-col justify-between gap-2 p-4 pb-4 overflow-hidden text-center border border-gray-300 rounded-md shadow-md`,
  single && tw`col-span-2`,
]);
const Image = tw.div`mb-4`;
const Bottom = tw.div`flex flex-col gap-2`;
const Name = tw.div`font-din mx-6 mt-2 text-lg text-secondary font-bold`;
const Categories = tw.div`flex flex-row justify-center flex-wrap gap-1 font-din py-1 mx-6 text-xs text-gray-400 uppercase border-t border-b border-gray-300`;
const Link = styled(Glink)`
  ${tw`mx-6 text-xs font-normal text-secondary hover:text-primary`};
`;

const AdvocatesListNew = ({ title, sponsors, ...rest }) => {
  const isSignatureGrand = title === "Signature Grand";
  const esquireSponsor = sponsors.find((sponsor) => {
    console.log(sponsor.name);
    return sponsor.name === "Esquire Digital";
  });

  const esquireSponsors = [esquireSponsor];

  return (
    <Section {...rest}>
      <Title>{title}</Title>
      <Grid>
        {sponsors.map(({ logo, name, categories }, index) => {
          if (name != "Esquire Digital")
            return (
              <GridItem
                key={index}
                single={sponsors.length === 1}
                double={sponsors.length === 2}
              >
                <div tw="flex flex-col justify-center h-full">
                  <Image>
                    <GatsbyImage
                      image={getImage(logo.localFile)}
                      alt={logo.altText}
                    />
                  </Image>
                </div>
                <Bottom>
                  <Name>{name}</Name>
                  <Categories>
                    {categories.map((category, i) => (
                      <span key={i}>{category}</span>
                    ))}
                  </Categories>
                  <Link
                    to={`/advocates/${name
                      .trim()
                      .toLowerCase()
                      .replace(/ /g, "-")}`}
                  >
                    Find out more
                  </Link>
                </Bottom>
              </GridItem>
            );
        })}
      </Grid>
      {isSignatureGrand && esquireSponsors.length > 0 && (
        <div tw="mt-16 pl-6 sm:pl-12">
          <Title>Patron Technology Sponsor</Title>
          <Grid>
            {esquireSponsors.map(({ logo, name, categories }, index) => {
              return (
                <GridItem key={index}>
                  <div tw="flex flex-col justify-center h-full">
                    <Image>
                      <GatsbyImage
                        image={getImage(logo.localFile)}
                        alt={logo.altText}
                      />
                    </Image>
                  </div>
                  <Bottom>
                    <Name>{name}</Name>
                    <Categories>
                      {categories.map((category, i) => (
                        <span key={i}>{category}</span>
                      ))}
                    </Categories>
                    <Link
                      to={`/advocates/${name
                        .trim()
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                    >
                      Find out more
                    </Link>
                  </Bottom>
                </GridItem>
              );
            })}
          </Grid>
        </div>
      )}
    </Section>
  );
};

export default AdvocatesListNew;
